const zh = {
    index: {
        install: '安装',
        name: "FORTAS",
        webTitle: '请使用手机扫描二维码下载',
        webTitle2:'请在Safari浏览器中打开',
        download: '下载',
        ratings: '评分',
        age: '年龄',
        complaint: '应用投诉',
        newFunction: '新功能',
        version: '版本',
        introduction: '应用介绍',
        rates: '评论及评分',
        outof: '满分5分',
        information: '信息',
        seller: '供应商',
        size: '大小',
        compatibility: '兼容性',
        languages: '语言',
        ageRating: '年龄分级',
        price: '价格',
        free: '免费',
        age18: '限18岁以上',
        langs: '中文和英文',
        ios: '需要IOS 9.0或更高版本',
        appPrivacy: '隐私政策',
        disclaim: '免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。',
        app: {
            info: '移动交易平台允许您接收金融工具的报价、使用图表和技术指标分析市场、执行模拟交易、连接经纪商进行实时交易以及查看交易操作的历史记录。',
            newf: '此版本包括错误修复和性能改进'

        },
        company: 'FORTAS',
        copyright: '© 2022 FORTAS  Ltd.',
        copyLink:  '复制',
        copySuccess:'复制成功'

    }


}

export default zh;
