const en = {
    index: {
        install: 'install',
        name: "FORTAS",
        webTitle: 'Please use your mobile phone to scan the QR code to download',
        webTitle2:'Please open in Safari browser',
        download: 'download',
        ratings: 'ratings',
        age: 'age',
        complaint: 'Complaint',
        newFunction: 'What\'s New',
        version: 'Version',
        introduction: 'Application Introduction',
        rates: 'Ratings and Reviews',
        outof: 'Out of 5',
        information: 'Information',
        seller: 'Seller',
        size: 'Size',
        compatibility: 'Compatibility',
        languages: 'Languages',
        ageRating: 'Age Rating',
        price: 'Price',
        free: 'Free',
        age18: '18+',
        langs: 'Chinese & English',
        ios: 'IOS 9.0+',
        appPrivacy: 'App Privacy',
        disclaim: 'Disclaimer: This website only provides developers with App download and installation hosting. The content and operation-related matters in the App are the responsibility of the App developer and have nothing to do with this website.',
        app: {
            info: 'The mobile trading platform allows you to receive quotes of financial instruments, analyze markets using charts and technical indicators, perform demo trades, connect to brokers for live trading and view the history of trading operations.',
            newf: 'This release includes bug fixes and performance improvements.'
        },
        company: 'FORTAS Pro',
        copyright: '© 2021 FORTAS  Ltd.',
        copyLink:  'Copy',
        copySuccess:'Copy Success'


    }


}


export default en;
